import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./components/Index";
import Header from "./common/Header";
import Footer from "./common/Footer";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
