import React from "react";

const Footer = () => {
  return (
    <>
      {/* Start Footer Seciton */}
      <footer>
        <div class="container">
          <div class="st-copyright-wrap text-center">
            <div class="st-copyright-text">
              © 2020. Designed by Laralink. All right reserved.
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer Seciton */}
    </>
  );
};

export default Footer;
